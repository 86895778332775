import React from 'react';
import {Routes, Route} from 'react-router-dom';
import TopMenu from "../components/TopMenu";
import LeftMenu from "../components/LeftMenu";
import Dashboard from "./admin/Dashboard";
import routes from "../routesConfig";
import PartCategories from "./admin/part/PartCategories";
import Repairs from "./admin/repairs/Repairs";
import Parts from "./admin/part/Parts";
import DeviceCategories from "./admin/device/DeviceCategories";
import Devices from "./admin/device/Devices";
import RepairCatalog from "./admin/device/RepairCatalog";
import "./Admin.css";

const Admin = () => {
    return (
        <div className="min-h-screen flex flex-col">
            <TopMenu className="flex-shrink-0"/>
            <div className="flex flex-grow overflow-hidden">
                <LeftMenu className="flex-shrink-0 w-64 bg-gray-800 text-white"/>
                <div className="flex-grow p-4">
                    <Routes>
                        <Route path={routes.admin.dashboard} element={<Dashboard/>}/>

                        {/*---------- ZAČÁTEK NÁHRADNÍ DÍLY ----------*/}
                        <Route path={routes.admin.part.categories} element={<PartCategories/>}/>
                        <Route path={routes.admin.part.parts} element={<Parts/>}/>
                        {/*---------- KONEC NÁHRADNÍ DÍLY ----------*/}

                        <Route path={routes.admin.repairs} element={<Repairs/>}/>

                        {/*---------- ZAČÁTEK ZAŘÍZENÍ ----------*/}
                        <Route path={routes.admin.device.categories} element={<DeviceCategories/>}/>
                        <Route path={routes.admin.device.items} element={<Devices/>}/>
                        <Route path={routes.admin.device.repairCatalog} element={<RepairCatalog/>}/>
                        {/*---------- KONEC ZAŘÍZENÍ ----------*/}
                    </Routes>
                </div>
            </div>
        </div>
    );
};

export default Admin;
