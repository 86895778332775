import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';

i18n
    .use(initReactI18next) // předá i18n instance do react-i18next
    .init({
        resources: {
            cs: {
                translation: {
                    login: "Přihlásit se",
                    loginToErp: 'Přihlášení do ERP systému',
                    typeYourPassword: "Zadejte heslo",
                    typeYourEmail: "Zadejte email",
                    didUForgotPassword: "Zapomněli jste heslo?",
                    passwordRecovery: "Obnova zapomenutého hesla",
                    send: 'Odeslat',
                    backToLogin: 'Zpět na přihlášení',
                    remember: 'Zapamatovat',
                    purchase: 'Správa výkupu',
                    repairManagement: 'Správa oprav',
                    warehouseManagement: 'Správa skladu',
                    deviceManagement: 'Správa zařízení',
                    dashboard: 'Dashboard',
                    categories: 'Kategorie',
                    description: 'Popis',
                    createdBy: 'Vytvořil',
                    name: 'Název',
                    createdAt: 'Vytvořeno',
                    warehouse: {
                        items: 'Položky skladu'
                    },
                    addCategory: 'Přidat kategorii',
                    confirmCategoryDeletion: 'Opravdu chcete smazat kategorii "{{title}}"?',
                    invalidCredentials: 'Špatné přihlašovací údaje',
                    addPart: 'Přidat díl',
                    confirmDeletion: 'Potvrdit smazání',
                    category: 'Kategorie',
                    save: 'Uložit',
                    discount: 'Sleva',
                    addDevice: 'Přidat zařízení',
                    addRepair: 'Přidat opravu',
                    updateDevice: 'Upravit zařízení',
                    addNewDevice: 'Přidat nové zařízení',
                    cancel: 'Zrušit',
                    confirm: 'Potvrdit',
                    update: 'Upravit',
                    active: 'Aktivní',
                    repairName: 'Oprava',
                    price: 'Cena',
                    repairList: 'Seznam oprav',
                    timeRequirement: 'Náročnost',
                    deleteImage: 'Smazat obrázek',
                    setNewPassword: 'Nastavte si nové heslo',
                    password: 'Heslo',
                    setNewPasswordAgain: 'Heslo znovu',
                    compatibleWith: 'Kompatibilní s',
                    addNewPart: 'Přidat díl',
                    editPart: 'Upravit díl',
                    categoryCode: 'Kód kategorie',
                    searchByName: 'Zadejte název produktu, nebo název, či kód kategorie',
                    filterByCategory: 'Filtrovat dle kategorie',
                    storagePlace: 'Místo uskladnění'
                }
            }
        },
        lng: "cs",
        fallbackLng: "cs",
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;
