export const API_ENDPOINTS = {
    login: '/login',
    refreshToken: '/refreshToken',
    dashboardData: '/dashboardData',
    part: {
        categories: '/partCategory',
        list: '/part',
        add: '/part',
        edit: '/part'
    },
    repairs: {
        list: '/repairs',
    },
    device: {
        listCategories: '/device/listCategories',
        list: '/device',
        add: '/device',
        base: '/device',
        category: 'device/category',
        catalogRepairs: '/device/catalogRepair',
    },
    user: {
        resetPassword: '/resetPassword',
        setNewPassword: '/setNewPassword'
    }
};