import React, {useEffect, useRef, useState} from 'react';
import {Modal, Box, Typography} from '@mui/material';
import {API_ENDPOINTS} from "../apiConfig";
import API from "../utils/API";
import Error from "./Error";
import Forms from "./Forms";
import {useTranslation} from 'react-i18next';

const PartCategoryModal = ({isOpen, onClose, onCategoryAdded, partCategory}) => {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [parentCategory, setParentCategory] = useState(null);
    const [categoryCode, setCategoryCode] = useState('');
    const inputRef = useRef(null);

    const [error, setError] = useState();
    const [categories, setCategories] = useState([]);
    const {t} = useTranslation();

    useEffect(() => {
        if (isOpen) {
            setTimeout(() => {
                inputRef.current.focus();
            }, 1);
        }
    }, [isOpen]);

    useEffect(() => {
        setName(partCategory ? partCategory.name : '');
        setDescription(partCategory ? partCategory.description : '');
        setCategoryCode(partCategory ? partCategory.code : '');
    }, [partCategory]);

    const fetchCategories = async () => {
        try {
            const response = await API.get(API_ENDPOINTS.part.categories);
            setCategories(response.data);
        } catch (error) {
            console.error("Failed to fetch categories:", error);
        }
    };

    useEffect(() => {
        fetchCategories();
    }, []);

    useEffect(() => {
        fetchCategories();
    }, [partCategory]);


    const handleSubmit = async (e) => {
        e.preventDefault();
        setError();

        const categoryData = {name, description, code: categoryCode};

        try {
            if (partCategory) {
                await API.put(`${API_ENDPOINTS.part.categories}/${partCategory._id}`, categoryData);
            } else {
                await API.post(API_ENDPOINTS.part.categories, categoryData);
            }
            onCategoryAdded();
            handleClose();
        } catch (error) {
            setError(error);
        }
    };

    const handleClose = () => {
        onClose();
    }

    return (
        <Modal
            open={isOpen}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <form onSubmit={handleSubmit}
                  className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white p-4 shadow-lg rounded-lg w-2/4">
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    {partCategory ? 'Upravit kategorii' : 'Přidat novou kategorii'}
                </Typography>
                <div className="grid grid-cols-2 gap-4 mb-4 items-start">
                    <Forms.Text
                        ref={inputRef}
                        required
                        placeholder="Název"
                        value={name}
                        onChange={(val) => setName(val)}
                        className="rounded-t-md"
                    />
                    <Forms.Text
                        required
                        placeholder="Kód kategorie (IP11PM)"
                        value={categoryCode}
                        onChange={(val) => setCategoryCode(val)}
                        className="rounded-t-md"
                    />
                    <Forms.Text
                        required
                        placeholder="Popis"
                        value={description}
                        onChange={(val) => setDescription(val)}
                        className="rounded-b-md"
                    />
                    <Forms.Select
                        required
                        placeholder="Nadřazená kategorie"
                        value={parentCategory}
                        options={categories.map(
                            category => ({
                                value: category._id,
                                label: category.name
                            })
                        )}
                        onChange={(val) => setParentCategory(val)}
                        className="rounded-b-md"
                    />
                </div>

                {error && <Error error={error}/>}
                <Forms.Button label={parentCategory ? t('update') : t('save')} className="mt-4"/>
            </form>
        </Modal>
    );
};

export default PartCategoryModal;
