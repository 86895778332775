import React, {useEffect, useRef, useState} from 'react';
import {Modal, Box, Typography} from '@mui/material';
import {API_ENDPOINTS} from "../apiConfig";
import API from "../utils/API";
import Error from "./Error";
import Forms from "./Forms";
import {useTranslation} from 'react-i18next';

const CatalogRepairModal = ({isOpen, onClose, onCategoryAdded, catalogRepair}) => {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [discount, setDiscount] = useState('');
    const [error, setError] = useState('');
    const {t} = useTranslation();
    const inputRef = useRef(null);

    useEffect(() => {
        setName(catalogRepair ? catalogRepair.name : '');
        setDescription(catalogRepair ? catalogRepair.description : '');
        setDiscount(catalogRepair ? catalogRepair.discount : '');
    }, [catalogRepair]);

    useEffect(() => {
        if (isOpen) {
            setTimeout(() => {
                inputRef.current.focus();
            }, 1);
        }
    }, [isOpen]);

    const handleSubmit = async (e) => {
        e?.preventDefault();
        setError();
        try {
            if (catalogRepair) {
                await API.put(`${API_ENDPOINTS.device.catalogRepairs}/${catalogRepair._id}`, {
                    name,
                    description,
                    discount
                });
            } else {
                await API.post(API_ENDPOINTS.device.catalogRepairs, {name, description, discount});
            }
            onCategoryAdded();
            handleClose();
        } catch (error) {
            setError(error);
        }
    };

    const handleClose = () => {
        onClose();
    }

    return (
        <Modal
            open={isOpen}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box
                className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-96 bg-white p-4 shadow-lg rounded-lg">
                <form onSubmit={handleSubmit}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        {catalogRepair ? 'Upravit kategorii' : 'Přidat novou opravu'}
                    </Typography>
                    <Forms.Text
                        required
                        ref={inputRef}
                        placeholder="Název"
                        value={name}
                        onChange={(val) => setName(val)}
                        className="rounded-t-md"
                    />
                    <Forms.Textarea
                        required
                        placeholder="Popis"
                        value={description}
                        onChange={(val) => setDescription(val)}
                        className="rounded-b-md"
                    />
                    <Forms.Number
                        required
                        placeholder="Globální sleva"
                        value={discount}
                        onChange={(val) => setDiscount(val)}
                        className="rounded-b-md"
                    />
                    {error && <Error error={error}/>}
                    <Forms.Button onClick={handleSubmit}
                                  label={catalogRepair ? t('update') : t('save')}
                                  className="mt-4"/>
                </form>
            </Box>
        </Modal>
    );
};

export default CatalogRepairModal;
