import React, {useEffect, useRef, useState} from 'react';
import {Modal, Box, Typography} from '@mui/material';
import {API_ENDPOINTS} from "../apiConfig";
import API from "../utils/API";
import Error from "./Error";
import Forms from "./Forms";
import {useTranslation} from 'react-i18next';

const typeOptions = [
    {value: 'sparePart', label: 'Náhradní díl'},
    {value: 'product', label: 'Produkt'}
];

const PartModel = ({isOpen, onClose, onPartUpdate, part}) => {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [type, setType] = useState('sparePart');
    const [categoryList, setCategoryList] = useState([]);
    const [storagePlace, setStoragePlace] = useState('');
    const [quantity, setQuantity] = useState(1);
    const [compatibleWith, setCompatibleWith] = useState([]);

    const [error, setError] = useState('');
    const [categories, setCategories] = useState([]);
    const [devices, setDevices] = useState([]);
    const {t} = useTranslation();
    const inputRef = useRef(null);

    useEffect(() => {
        if (isOpen) {
            setTimeout(() => {
                inputRef.current.focus();
            }, 1);
        }
    }, [isOpen]);

    useEffect(() => {
        if (part) {
            setName(part.name);
            setDescription(part.description);
            setType(part.type ? typeOptions.find(type => type.value === part.type) : typeOptions[0]);
            setCategoryList(part.categoryList ? part.categoryList.map(cat => ({label: cat.name, value: cat._id})) : []);
            setCompatibleWith(part.compatibleWith ? part.compatibleWith.map(dev => ({
                label: dev.name,
                value: dev._id
            })) : []);
            setStoragePlace(part.storagePlace);
            setQuantity(part.quantity);
        } else {
            resetForm();
        }
    }, [part]);

    const resetForm = () => {
        setName('');
        setDescription('');
        setType(typeOptions[0]);
        setCategoryList([]);
        setStoragePlace('');
        setQuantity(1);
        setCompatibleWith([]);
    };

    const fetchCategories = async () => {
        try {
            const response = await API.get(API_ENDPOINTS.part.categories);
            setCategories(response.data);
        } catch (error) {
            console.error("Failed to fetch categories:", error);
        }
    };

    const fetchDevices = async () => {
        try {
            const response = await API.get(API_ENDPOINTS.device.list);
            setDevices(response.data);
        } catch (error) {
            console.error("Failed to fetch devices:", error);
        }
    };

    useEffect(() => {
        fetchCategories();
        fetchDevices();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();

        setError();
        try {
            const partData = {
                name,
                description,
                type: type.value,
                categoryList: categoryList?.map(cat => cat.value),
                storagePlace,
                quantity,
                compatibleWith: compatibleWith?.map(dev => dev.value)
            };
            if (part) {
                await API.put(`${API_ENDPOINTS.part.edit}/${part._id}`, partData);
            } else {
                await API.post(API_ENDPOINTS.part.add, partData);
            }
            onPartUpdate();
            handleClose();
        } catch (error) {
            setError(error);
        }
    };

    const handleClose = () => {
        onClose();
        resetForm();
    }

    return (
        <Modal
            open={isOpen}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box
                className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white p-4 shadow-lg rounded-lg w-2/4">
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    {part ? t('editPart') : t('addNewPart')}
                </Typography>
                <form onSubmit={handleSubmit}>
                    <div className="grid grid-cols-2 gap-4 mb-4 items-start">
                        <Forms.Text
                            ref={inputRef}
                            required
                            placeholder={t('name')}
                            value={name}
                            onChange={(val) => setName(val)}
                            className="rounded-t-md"
                        />
                        <Forms.Text
                            placeholder={t('description')}
                            value={description}
                            onChange={(val) => setDescription(val)}
                            className="rounded-b-md"
                        />
                        <Forms.Select
                            required
                            placeholder={t('type')}
                            value={type}
                            options={typeOptions}
                            onChange={(val) => setType(val)}
                            className="rounded-b-md"
                        />
                        <Forms.Select
                            required
                            multiple
                            placeholder={t('category')}
                            value={categoryList}
                            options={categories.map(cat => ({
                                value: cat._id,
                                label: cat.name,
                                code: cat.code
                            }))}
                            onChange={(val) => setCategoryList(val)}
                            className="rounded-b-md"
                        />
                        <Forms.Select
                            multiple
                            placeholder={t('complatibleWith')}
                            value={compatibleWith}
                            options={devices.map(dev => ({
                                value: dev._id,
                                label: dev.name
                            }))}
                            onChange={(val) => setCompatibleWith(val)}
                            className="rounded-b-md"
                        />
                        <Forms.Text
                            required
                            placeholder={t('storagePlace')}
                            value={storagePlace}
                            onChange={(val) => setStoragePlace(val)}
                            className="rounded-b-md"
                        />
                        <Forms.Text
                            required
                            type="number"
                            placeholder={t('quantity')}
                            value={quantity}
                            onChange={(val) => setQuantity(val)}
                            className="rounded-b-md"
                        />
                    </div>

                    {error && <Error error={error}/>}
                    <Forms.Button label={part ? t('update') : t('save')} className="mt-4"/>
                </form>
            </Box>
        </Modal>
    );
};

export default PartModel;
