import React, {useEffect, useState} from 'react';
import {Box, Checkbox, TextField, MenuItem, Select, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {useDrag, useDrop, DndProvider} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';
import {ReactComponent as DeleteIcon} from '../assets/images/delete.svg';
import {ReactComponent as DragIcon} from "../assets/images/drag-icon.svg";

import "./RepairSelector.css";

// Typ pro drag-and-drop
const ItemType = 'REPAIR';

const RepairItem = ({repair, index, moveRepair, handleRepairChange, handleRemoveRepair, onDragEnd}) => {
    const [{isDragging}, drag] = useDrag({
        type: ItemType,
        item: {index},
        end: onDragEnd,
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const [, drop] = useDrop({
        accept: ItemType,
        hover: (draggedItem) => {
            if (draggedItem.index !== index) {
                moveRepair(draggedItem.index, index);
                draggedItem.index = index;
            }
        },
    });

    return (
        <Box
            ref={(node) => drag(drop(node))}
            display="flex"
            alignItems="center"
            mt={1}
            sx={{
                opacity: isDragging ? 0.5 : 1,
                backgroundColor: isDragging ? '#f0f0f0' : 'transparent',
                minHeight: '60px',
                width: '100%',
                cursor: 'grab'
            }}
        >
            <Box sx={{width: 40, display: 'flex', justifyContent: 'center'}}>
                <DragIcon height={20}/>
            </Box>
            <Checkbox
                checked={repair.active}
                onChange={(e) => handleRepairChange(index, 'active', e.target.checked)}
            />
            <Typography sx={{flexGrow: 1}}>{repair.name}</Typography>
            <TextField
                type="number"
                label="Cena"
                value={repair.price}
                onChange={(e) => handleRepairChange(index, 'price', e.target.value)}
                sx={{width: 100, ml: 2}}
            />
            <TextField
                type="text"
                label="timeRequirement"
                value={repair.timeRequirement}
                onChange={(e) => handleRepairChange(index, 'timeRequirement', e.target.value)}
                sx={{width: 100, ml: 2}}
            />
            <DeleteIcon className="w-4 cursor-pointer fill-red-700 m-1" onClick={() => handleRemoveRepair(index)}/>
        </Box>
    );
};

const RepairSelector = ({repairCatalog, repairList = [], onRepairsChange}) => {
    const {t} = useTranslation();
    const [selectedRepairs, setSelectedRepairs] = useState([]);
    const [draggingIndex, setDraggingIndex] = useState(null); // Stav pro sledování přetažení

    useEffect(() => {
        const initialRepairs = repairList.map(item => {
            const repair = repairCatalog.find(r => r._id === item.repair);
            return {
                ...repair,
                price: item.price,
                active: item.active,
                timeRequirement: item.timeRequirement
            };
        });
        setSelectedRepairs(initialRepairs);
    }, [repairList, repairCatalog, onRepairsChange]);

    const availableRepairs = repairCatalog.filter(
        (repair) => !selectedRepairs.some((selected) => selected._id === repair._id)
    );

    const handleAddRepair = (repair) => {
        const newSelectedRepairs = [...selectedRepairs, {...repair, price: 0, active: true, timeRequirement: ''}];
        setSelectedRepairs(newSelectedRepairs);
        onRepairsChange(newSelectedRepairs);
    };

    const handleRepairChange = (index, field, value) => {
        const updatedRepairs = [...selectedRepairs];
        updatedRepairs[index][field] = value;
        setSelectedRepairs(updatedRepairs);
        onRepairsChange(updatedRepairs);
    };

    const handleRemoveRepair = (index) => {
        const updatedRepairs = selectedRepairs.filter((_, i) => i !== index);
        setSelectedRepairs(updatedRepairs);
        onRepairsChange(updatedRepairs);
    };

    const moveRepair = (fromIndex, toIndex) => {
        const updatedRepairs = [...selectedRepairs];
        const [movedRepair] = updatedRepairs.splice(fromIndex, 1);
        updatedRepairs.splice(toIndex, 0, movedRepair);
        setSelectedRepairs(updatedRepairs);
    };

    const handleDragEnd = () => {
        onRepairsChange(selectedRepairs);
        setDraggingIndex(null);
    };

    return (
        <DndProvider backend={HTML5Backend}>
            <Box>
                <Select
                    variant="outlined"
                    onChange={(e) => handleAddRepair(availableRepairs.find(r => r._id === e.target.value))}
                    displayEmpty
                    fullWidth
                    value=""
                >
                    <MenuItem value="" disabled>
                        {t('addRepair')}
                    </MenuItem>
                    {availableRepairs.map((repair) => (
                        <MenuItem key={repair._id} value={repair._id}>
                            {repair.name}
                        </MenuItem>
                    ))}
                </Select>
                {selectedRepairs.length ? (
                    <Box display="flex" alignItems="center" mt={2}>
                        <Typography variant="subtitle2" sx={{width: 40, mr: 2}}>
                            {t('active')}
                        </Typography>
                        <Typography variant="subtitle2" sx={{flexGrow: 1}}>
                            {t('repairName')}
                        </Typography>
                        <Typography variant="subtitle2" sx={{width: 100, ml: 2}}>
                            {t('price')}
                        </Typography>
                        <Typography variant="subtitle2" sx={{width: 100, ml: 2}}>
                            {t('timeRequirement')}
                        </Typography>
                    </Box>
                ) : null}

                <div className="selected-repairs">
                    {selectedRepairs.map((repair, index) => (
                        <RepairItem
                            key={repair._id}
                            repair={repair}
                            index={index}
                            moveRepair={moveRepair}
                            handleRepairChange={handleRepairChange}
                            handleRemoveRepair={handleRemoveRepair}
                            onDragEnd={handleDragEnd}
                        />
                    ))}
                </div>
                {/* Přidáme posluchač na konec přetahování */}
                {draggingIndex === null && (
                    <button style={{display: 'none'}} onMouseUp={handleDragEnd}></button>
                )}
            </Box>
        </DndProvider>
    );
};

export default RepairSelector;
