import React, {useEffect} from 'react';
import {BrowserRouter as Router, Routes, Route, Navigate, useNavigate, useLocation} from 'react-router-dom';
import {AuthProvider, useAuth} from "./contexts/AuthContext";
import Login from './features/Auth/Login';
import ForgotPassword from './features/Auth/ForgotPassword';
import PrivateRoute from './components/PrivateRoute';
import Admin from "./pages/Admin";
import routes from "./routesConfig";
import SetNewPassword from "./features/Auth/SetNewPassword";

const AppRoutes = () => {
    const {isAuthenticated} = useAuth();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (isAuthenticated) {
            if (location.pathname === '/') {
                navigate(routes.admin.dashboardFull);
            }
        }
    }, [isAuthenticated, navigate, location.pathname]);

    return (
        <Routes>
            <Route path="/reset-password/:recoveryString" element={<SetNewPassword/>}/>
            <Route path={routes.login} element={<Login/>}/>
            <Route path={routes.forgotPassword} element={<ForgotPassword/>}/>
            <Route path={`${routes.admin.base}/*`} element={<PrivateRoute element={<Admin/>}/>}/>
            <Route path="/"
                   element={<Navigate replace to={isAuthenticated ? routes.admin.dashboardFull : routes.login}/>}/>
        </Routes>
    );
};

const App = () => {
    return (
        <AuthProvider>
            <Router>
                <AppRoutes/>
            </Router>
        </AuthProvider>
    );
};

export default App;
