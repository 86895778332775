import React, {useEffect, useRef, useState} from 'react';
import {Modal, Box, Typography} from '@mui/material';
import {API_ENDPOINTS} from "../apiConfig";
import API from "../utils/API";
import Error from "./Error";
import Forms from "./Forms";
import {useTranslation} from 'react-i18next';
import RepairSelector from "./RepairSelector";
import "./DeviceModal.css";

const DeviceModal = ({isOpen, onClose, onDeviceUpdate, device}) => {
    const [name, setName] = useState('');
    const [category, setCategory] = useState();
    const [repairList, setRepairList] = useState([]);
    const [image, setImage] = useState(null);
    const [categories, setCategories] = useState([]);
    const [repairCatalog, setRepairCatalog] = useState([]);
    const [imagePath, setImagePath] = useState(null);
    const [deleteImage, setDeleteImage] = useState(false);
    const inputRef = useRef(null);

    const [error, setError] = useState('');
    const {t} = useTranslation();

    useEffect(() => {
        if (isOpen) {
            setTimeout(() => {
                inputRef.current.focus();
            }, 1);
        }
    }, [isOpen]);

    useEffect(() => {
        setName(device ? device.name : '');
        setImagePath(device ? device.image : null);
        setCategory(device?.category.length ? {value: device.category[0]._id, label: device.category[0].name} : null);
        setRepairList(device?.repairList.map(repair => ({
            price: repair.price,
            repair: repair.repair._id,
            active: repair.active,
            timeRequirement: repair.timeRequirement
        })));
    }, [device]);

    useEffect(() => {
        const fetchCategories = async () => {
            const response = await API.get(API_ENDPOINTS.device.listCategories);
            setCategories(response.data);
        };
        const fetchRepairs = async () => {
            const response = await API.get(API_ENDPOINTS.device.catalogRepairs);
            setRepairCatalog(response.data);
        };

        fetchCategories();
        fetchRepairs();
    }, []);

    const handleSubmit = async (e) => {
        e?.preventDefault();
        setError();

        const formData = new FormData();
        formData.append('name', name);
        formData.append('category', category?.value);
        image && formData.append('image', image);
        formData.append('deleteImage', deleteImage);
        repairList && formData.append('repairList', JSON.stringify(repairList));

        try {
            if (device) {
                await API.put(`${API_ENDPOINTS.device.base}/${device._id}`, formData);
            } else {
                await API.post(API_ENDPOINTS.device.base, formData);
            }

            onDeviceUpdate();
            handleClose();
        } catch (error) {
            setError(error);
            console.error("Failed to update device:", error);
        }
    };

    const handleClose = () => {
        setImage(null);
        setDeleteImage(false);
        onClose();
    }

    const onRepairsChange = (updated) => {
        setRepairList(updated.map(({_id, price, active, timeRequirement}) => ({
            repair: _id, price, active, timeRequirement
        })));
    }

    const renderImage = () => {
        if (imagePath) {
            return <div>
                <Forms.Checkbox name="deleteImage" value={deleteImage} label={t('deleteImage')}
                                onChange={() => setDeleteImage(!deleteImage)}/>
                <img className="preview-image"
                     src={`${process.env.REACT_APP_IMAGE_URL}/public/device-images/${imagePath}`}
                     alt=""/>
            </div>
        }
    }

    return (<Modal
            open={isOpen}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="DeviceModal"
        >
            <Box
                className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white p-4 shadow-lg rounded-lg w-2/4"
            >
                <form onSubmit={handleSubmit}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        {device ? t('updateDevice') : t('addNewDevice')}
                    </Typography>

                    <div className="grid grid-cols-2 gap-4 mb-4 items-start">
                        <Forms.Text
                            ref={inputRef}
                            required
                            placeholder="Název"
                            value={name}
                            onChange={(val) => setName(val)}
                            className="rounded-md"
                        />
                        <Forms.Select
                            required
                            placeholder="Kategorie"
                            value={category}
                            options={categories.map(category => ({
                                value: category._id, label: category.name
                            }))}
                            onChange={(val) => setCategory(val)}
                            className="rounded-md"
                        />
                        <Forms.File
                            placeholder="Kategorie"
                            onChange={e => setImage(e.target.files[0])}
                            className="rounded-md"
                        />
                        {renderImage()}
                    </div>
                    <div className="mb-4">
                        <RepairSelector
                            repairCatalog={repairCatalog}
                            onRepairsChange={onRepairsChange}
                            repairList={repairList}
                        />
                    </div>

                    {error && <Error error={error}/>}
                    <Forms.Button
                        label={device ? t('update') : t('save')}
                        className="mt-4"
                    />
                </form>
            </Box>
        </Modal>

    );
};

export default DeviceModal;