import React from 'react';

const EmailInput = ({label, name, value, onChange, key, className = ''}) => {
    if (!key) {
        key = `${Math.random() * 99999}`;
    }
    return (
        <label className="flex items-center ml-2  text-sm text-gray-900 gap-2">
            <input
                id={`checkboxId_${key}`}
                name={name}
                type="checkbox"
                checked={value}
                onChange={(e) => onChange(e.target.checked)}
                className={`h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded ${className}`}
            />
            {label}
        </label>
    );
};

export default EmailInput;
