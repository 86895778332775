import React, {useState, useEffect} from 'react';
import {API_ENDPOINTS} from "../../../apiConfig";
import DataGrid from "../../../components/DataGrid";
import PartCategoryModal from "../../../components/PartCategoryModal";
import ConfirmModal from "../../../components/ConfirmModal";
import API from "../../../utils/API";
import moment from 'moment';
import {useTranslation} from 'react-i18next';
import Forms from "../../../components/Forms";
import {ReactComponent as DeleteIcon} from '../../../assets/images/delete.svg';
import {ReactComponent as EditIcon} from '../../../assets/images/edit.svg';

const PartCategories = () => {
    const [categories, setCategories] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
    const [categoryToDelete, setCategoryToDelete] = useState(null);
    const {t} = useTranslation();
    const [error, setError] = useState();
    const [partCategoryToEdit, setPartCategoryToEdit] = useState();

    const fetchCategories = async () => {
        const response = await API.get(API_ENDPOINTS.part.categories);
        setCategories(response.data);
    };


    useEffect(() => {
        fetchCategories();
    }, []);

    const columns = [
        {
            key: 'name',
            name: t('name'),
            renderCell: ({row}) => (
                <label className="flex justify-between">
                    {row.name}
                    <div className="flex">
                        <DeleteIcon className="w-4 cursor-pointer fill-red-700 m-2"
                                    onClick={() => handleDeleteCategory(row)}/>
                        <EditIcon className="w-4 cursor-pointer text-blue-800 h-full"
                                  onClick={() => handleEditPartCategory(row)}/>
                    </div>
                </label>
            )
        },
        {
            key: 'code',
            name: t('categoryCode')
        },
        {
            key: 'description',
            name: t('description')
        },
        {
            key: 'createdBy',
            name: t('createdBy'),
            renderCell: ({row}) => `${row.createdBy.name} ${row.createdBy.surname}`
        },
        {
            key: 'createdAt',
            name: t('createdAt'),
            renderCell: ({row}) => moment(row.createdAt).format('DD.MM.YYYY HH:mm')
        }
    ];

    const handleEditPartCategory = (deviceCategory) => {
        setPartCategoryToEdit(deviceCategory);
        setIsModalOpen(true);
    };

    const handleDeleteCategory = (category) => {
        setCategoryToDelete(category);
        setIsConfirmModalOpen(true);
    };

    const confirmDeleteCategory = async () => {
        try {
            await API.delete(`${API_ENDPOINTS.part.categories}/${categoryToDelete._id}`);
            setCategories(categories.filter(category => category._id !== categoryToDelete._id));
            setIsConfirmModalOpen(false);
        } catch (error) {
            setError(error);
            console.error("Failed to delete category:", error);
        }
    };

    const onCloseConfirmModal = () => {
        setIsConfirmModalOpen(false);
        setError(null);
    }

    const openNewPartCategoryModal = () => {
        setPartCategoryToEdit(null);
        setIsModalOpen(true);
    }

    return (
        <div>
            <Forms.Button label={t('addCategory')} onClick={openNewPartCategoryModal} className="mb-8"/>
            <DataGrid showCreatedBy rows={categories} columns={columns}/>
            <PartCategoryModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                onCategoryAdded={fetchCategories}
                partCategory={partCategoryToEdit}
            />
            <ConfirmModal
                isOpen={isConfirmModalOpen}
                onClose={onCloseConfirmModal}
                error={error}
                onConfirm={confirmDeleteCategory}
                title="Confirm Deletion"
                message={t('confirmCategoryDeletion', {title: categoryToDelete?.name})}
            />
        </div>
    );
};

export default PartCategories;
