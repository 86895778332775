import React, {useState} from 'react';
import {useAuth} from "../../contexts/AuthContext";
import Forms from "../../components/Forms";
import {Link, useNavigate} from "react-router-dom";
import API from "../../utils/API";
import Error from "../../components/Error";
import {useTranslation} from 'react-i18next';
import logo from '../../assets/images/logo.svg';
import routes from "../../routesConfig";
import {API_ENDPOINTS} from "../../apiConfig";

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [remember, setRemember] = useState(false);
    const {dispatch} = useAuth();
    const {t} = useTranslation();
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');

        try {
            const response = await API.post(API_ENDPOINTS.login, {email, password, remember});

            if (!response.status === 200) {
                throw new Error('Došlo k chybě při přihlašování.');
            }

            const {accessToken, refreshToken, user} = response.data;

            dispatch({
                type: 'LOGIN',
                payload: {user, accessToken, refreshToken},
            });

            navigate(routes.admin.dashboardFull);
        } catch (error) {
            setError(error);
        }
    };

    return (
        <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100">
            <div className="max-w-lg w-full space-y-8 p-10 bg-white rounded-lg shadow-md">
                <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
                    {t('loginToErp')}
                    <img className="inline" src={logo} alt="Logo"/>
                </h2>
                <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
                    <div className="rounded-md shadow-sm -space-y-px">
                        <Forms.Email
                            name="email"
                            value={email}
                            onChange={(val) => setEmail(val)}
                            placeholder={t('typeYourEmail')}
                            className="rounded-t-md"
                        />
                        <Forms.Password
                            name="password"
                            value={password}
                            onChange={(val) => setPassword(val)}
                            placeholder={t('typeYourPassword')}
                            className="rounded-b-md"
                        />
                    </div>

                    <div className="flex items-center justify-between">
                        <Forms.Checkbox
                            name="remember"
                            label={t('remember')}
                            value={remember}
                            onChange={val => setRemember(val)}
                        />
                        <div className="text-sm">
                            <Link to={routes.forgotPassword}
                                  className="font-medium text-indigo-600 hover:text-indigo-500">
                                {t('didUForgotPassword')}
                            </Link>
                        </div>
                    </div>

                    <div>
                        <button
                            type="submit"
                            className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                            {t('login')}
                        </button>
                    </div>
                </form>
            </div>
            <Error error={error}/>
        </div>
    );
};

export default Login;
