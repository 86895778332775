import React, {createContext, useContext, useReducer, useCallback, useEffect} from 'react';
import axios from 'axios';

const initialState = {
    isAuthenticated: !!localStorage.getItem('accessToken'),
    user: null,
    accessToken: localStorage.getItem('accessToken'),
    refreshToken: localStorage.getItem('refreshToken'),
};

const AuthContext = createContext(initialState);

const authReducer = (state, action) => {
    switch (action.type) {
        case 'LOGIN':
            localStorage.setItem('accessToken', action.payload.accessToken);
            localStorage.setItem('refreshToken', action.payload.refreshToken);
            return {
                ...state,
                isAuthenticated: true,
                user: action.payload.user,
                accessToken: action.payload.accessToken,
                refreshToken: action.payload.refreshToken,
            };
        case 'LOGOUT':
            localStorage.removeItem('accessToken');
            localStorage.removeItem('refreshToken');
            return {
                ...state,
                isAuthenticated: false,
                user: null,
                accessToken: null,
                refreshToken: null,
            };
        case 'RENEW_TOKEN':
            localStorage.setItem('accessToken', action.payload.accessToken);
            return {
                ...state,
                accessToken: action.payload.accessToken,
            };
        default:
            return state;
    }
};

export const AuthProvider = ({children}) => {
    const [state, dispatch] = useReducer(authReducer, initialState);

    const renewToken = useCallback(async () => {
        try {
            const response = await axios.post('/api/renewToken', {
                refreshToken: state.refreshToken,
            });
            dispatch({
                type: 'RENEW_TOKEN',
                payload: {
                    accessToken: response.data.accessToken,
                },
            });
        } catch (error) {
            console.error('Failed to renew access token', error);
            dispatch({type: 'LOGOUT'});
        }
    }, [state.refreshToken]);

    // Přidání ověření accessToken při startu
    useEffect(() => {
        const verifyToken = async () => {
            if (state.accessToken && state.refreshToken) {
                try {
                    // Ověřte platnost accessToken pomocí vhodného endpointu nebo logiky
                    // Toto je jen příklad, nahraďte /api/verifyToken vaším skutečným endpointem
                    const {data} = await axios.get('/api/verifyToken', {
                        headers: {
                            Authorization: `Bearer ${state.accessToken}`,
                        },
                    });
                    // Pokud je token stále platný, nastavte isAuthenticated na true
                    dispatch({
                        type: 'LOGIN',
                        payload: {
                            user: data, // Získat userData z odpovědi, pokud je to potřeba
                            accessToken: state.accessToken,
                            refreshToken: state.refreshToken,
                        },
                    });
                } catch (error) {
                    console.error('Token verification failed', error);
                    renewToken(); // Pokus o obnovení tokenu, pokud ověření selže
                }
            }
        };

        verifyToken();
    }, [state.accessToken, state.refreshToken, renewToken]);

    return (
        <AuthContext.Provider value={{...state, dispatch, renewToken}}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
