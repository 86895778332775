import React, {useState} from "react";
import Forms from "../../components/Forms";
import {Link, useNavigate, useParams} from "react-router-dom";
import API from "../../utils/API";
import {API_ENDPOINTS} from "../../apiConfig";
import {useTranslation} from "react-i18next";

import "./SetNewPassword.css";

const SetNewPassword = () => {
    const {recoveryString} = useParams();
    const navigate = useNavigate();
    const [password, setPassword] = useState('');
    const [passwordConfirmation, setPasswordConfirmation] = useState('');
    const {t} = useTranslation();

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (passwordConfirmation !== password) {
            alert('Hesla se neshodují');
        }

        try {
            await API.post(API_ENDPOINTS.user.setNewPassword, {
                recoveryString,
                password,
                passwordConfirmation
            });
            alert('Heslo úspěšně změněno, můžete se přihlásit kurva');
            navigate('/');
        } catch (e) {
            console.log(e);
        }
    }

    return (
        <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100">
            <div className="max-w-lg w-full space-y-8 p-10 bg-white rounded-lg shadow-md">
                <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
                    {t('setNewPassword')}
                </h2>
                <div className="container">
                    <form className="login-form" onSubmit={handleSubmit}>
                        <Forms.Text
                            type="password"
                            value={password}
                            onChange={val => setPassword(val)}
                            required
                            label={t('password')}
                        />
                        <Forms.Text
                            type="password"
                            label={t('setNewPasswordAgain')}
                            value={passwordConfirmation}
                            onChange={val => setPasswordConfirmation(val)}
                            required
                        />
                        <div className="controls">
                            <Forms.Button type="submit" label={t('save')}/>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default SetNewPassword;
