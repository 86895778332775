import React from 'react';
import {useLocation} from 'react-router-dom';
import {ReactComponent as LogoutIcon} from '../assets/images/logout.svg';
import logo from '../assets/images/logo.png';
import {useAuth} from "../contexts/AuthContext";

const TopMenu = () => {
    const location = useLocation();
    const {user, dispatch} = useAuth();

    // Funkce pro odhlášení
    const handleLogout = () => {
        dispatch({type: 'LOGOUT'});
    };

    return (
        <div className="bg-blue-500 text-white p-4 flex justify-between items-center">
            <div>
                Název stránky: {location.pathname}
            </div>

            <div>
                <img src={logo} alt="Logo" className="h-8"/> {/* Upravte cestu k logu */}
            </div>

            <div className="flex items-center">
                <span>{user && `${user.name} ${user.surname}`}</span> {/* Upravte podle struktury vašeho uživatelského objektu */}
                <button onClick={handleLogout} className="ml-4">
                    <LogoutIcon className="fill-current h-6 w-6"/>
                </button>
            </div>
        </div>
    );
};

export default TopMenu;
