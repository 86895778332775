import React, {useState, useEffect} from 'react';
import {API_ENDPOINTS} from "../../../apiConfig";
import DataGrid from "../../../components/DataGrid";
import ConfirmModal from "../../../components/ConfirmModal";
import API from "../../../utils/API";
import moment from 'moment';
import {useTranslation} from 'react-i18next';
import Forms from "../../../components/Forms";
import {ReactComponent as DeleteIcon} from '../../../assets/images/delete.svg';
import CatalogRepairModal from "../../../components/CatalogRepairModal";
import {ReactComponent as EditIcon} from '../../../assets/images/edit.svg';

import "./RepairCatalog.css";

const RepairCatalog = () => {
    const [categories, setCategories] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
    const [deviceToDelete, setDeviceToDelete] = useState(null);
    const [catalogRepairToEdit, setCatalogRepairToEdit] = useState(null);
    const {t} = useTranslation();
    const [error, setError] = useState();

    const fetchCategories = async () => {
        const response = await API.get(API_ENDPOINTS.device.catalogRepairs);
        setCategories(response.data);
    };


    useEffect(() => {
        fetchCategories();
    }, []);

    const columns = [
        {
            key: 'name',
            name: t('name'),
            width: 300,
            renderCell: ({row}) => (
                <label className="flex justify-between">
                    <label className="label">{row.name}</label>
                    <div className="flex">
                        <DeleteIcon className="w-4 cursor-pointer fill-red-700 m-2"
                                    onClick={() => handleDeleteDeviceCategory(row)}/>
                        <EditIcon className="w-4 cursor-pointer text-blue-800 h-full"
                                  onClick={() => handleEditDeviceCategory(row)}/>
                    </div>
                </label>
            )
        },
        {key: 'description', name: t('description')},
        {key: 'discount', name: t('discount'), width: 80},
        {
            key: 'createdBy',
            name: t('createdBy'),
            width: 150,
            renderCell: ({row}) => `${row.createdBy.name} ${row.createdBy.surname}`
        },
        {
            key: 'createdAt',
            name: t('createdAt'),
            width: 150,
            renderCell: ({row}) => moment(row.createdAt).format('DD.MM.YYYY HH:mm')
        }
    ];
    const handleEditDeviceCategory = (category) => {
        setCatalogRepairToEdit(category);
        setIsModalOpen(true);
    };

    const handleDeleteDeviceCategory = (category) => {
        setDeviceToDelete(category);
        setIsConfirmModalOpen(true);
    };

    const confirmDeleteDeviceCategory = async () => {
        try {
            await API.delete(`${API_ENDPOINTS.device.catalogRepairs}/${deviceToDelete._id}`);
            setCategories(categories.filter(category => category._id !== deviceToDelete._id));
            setIsConfirmModalOpen(false);
        } catch (error) {
            setError(error);
            console.error("Failed to delete category:", error);
        }
    };

    const onCloseConfirmModal = () => {
        setIsConfirmModalOpen(false);
        setError(null);
    }

    const openAddCategoryModal = () => {
        setIsModalOpen(true);
        setCatalogRepairToEdit(null)
    }

    return (
        <div className="RepairCatalog">
            <Forms.Button label={t('addRepair')} onClick={openAddCategoryModal} className="mb-8"/>
            <DataGrid showCreatedBy rows={categories} columns={columns}/>
            <CatalogRepairModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                onCategoryAdded={fetchCategories}
                catalogRepair={catalogRepairToEdit}
            />
            <ConfirmModal
                isOpen={isConfirmModalOpen}
                onClose={onCloseConfirmModal}
                error={error}
                onConfirm={confirmDeleteDeviceCategory}
                title="Confirm Deletion"
                message={t('confirmDeviceDeletion', {title: deviceToDelete?.name})}
            />
        </div>
    );
};

export default RepairCatalog;
