import React, {forwardRef} from 'react';

const TextInput = forwardRef(({label, name, value, onChange, placeholder, className = '', onKeyDown}, ref) => (
    <div>
        <label htmlFor={name}>{label}</label>
        <input
            onKeyDown={onKeyDown}
            ref={ref}
            id={name}
            name={name}
            type="text"
            value={value}
            onChange={e => onChange(e.target.value)}
            placeholder={placeholder}
            className={`appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm ${className}`}
        />
    </div>
));

export default TextInput;
