import React from 'react';

const Button = ({label, onClick, className = ''}) => (
    <button
        type="submit"
        onClick={() => onClick?.()}
        className={`group relative flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ${className}`}>
        {label}
    </button>
);

export default Button;
