import axios from 'axios';
import {useAuth} from '../contexts/AuthContext';

const API = axios.create({
    baseURL: `${process.env.REACT_APP_PROXY_HOST || ''}/api`,
});

// Nastavení autorizační hlavičky pro každý request
API.interceptors.request.use((config) => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
        config.headers['Authorization'] = `Bearer ${accessToken}`;
    }
    return config;
}, error => Promise.reject(error));

API.interceptors.response.use(
    response => response,
    async (error) => {
        const originalRequest = error.config;
        console.log(error);
        if (error.response.status === 401 && error.response.data.code === "TokenExpired" && !originalRequest._retry) {
            const {renewToken, accessToken} = useAuth();
            originalRequest._retry = true;
            await renewToken();
            axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
            originalRequest.headers['Authorization'] = `Bearer ${accessToken}`;
            return axios(originalRequest);
        }
        return Promise.reject(error);
    }
);

export default API;
