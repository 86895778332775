import React, { useEffect } from 'react';
import { Modal, Box, Typography } from '@mui/material';
import Forms from "./Forms";
import { useTranslation } from 'react-i18next';
import ErrorMessage from "./Error";

const ConfirmModal = ({ isOpen, onClose, onConfirm, title, message, error }) => {
    const { t } = useTranslation();

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                onClose();
            } else if (event.key === 'Enter') {
                onConfirm();
            }
        };

        if (isOpen) {
            window.addEventListener('keydown', handleKeyDown);
        }

        // Cleanup function to remove event listener
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [isOpen, onClose, onConfirm]); // Dependencies array ensures effect runs only if these values change

    return (
        <Modal
            open={isOpen}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div
                className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-96 bg-white p-4 shadow-lg rounded-lg"
            >
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    {title}
                </Typography>
                <Typography id="modal-modal-description">
                    {message}
                </Typography>
                <div className="mt-4 flex justify-between">
                    <Forms.Button onClick={onClose} label={t('cancel')} className="mr-4"/>
                    <Forms.Button onClick={onConfirm} label={t('confirm')} className="ml-4"/>
                </div>
                {error && <ErrorMessage error={error}/>}
            </div>
        </Modal>
    );
};

export default ConfirmModal;
