import React from 'react';
import {useTranslation} from 'react-i18next';

const ErrorMessage = ({error}) => {
    const {t} = useTranslation();

    if (!error) {
        return null;
    }

    let errorMessage = 'Došlo k neznámé chybě';
    if (error?.response?.data?.error?.message && (error?.response?.data?.errorCode === 'mongoCreateFailed' || error?.response?.data?.errorCode === 'mongoUpdateFailed')) {
        errorMessage = error?.response?.data?.error?.message;
    } else if (error?.response?.data?.errorCode) {
        errorMessage = t(error.response.data.errorCode);
    } else if (error && error.message) {
        errorMessage = error.message;
    }

    return (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mt-4" role="alert">
            <strong className="font-bold">Chyba:</strong>
            <span className="block sm:inline"> {errorMessage}</span>
        </div>
    );
};

export default ErrorMessage;
