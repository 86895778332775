import React, {useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import Forms from "../../components/Forms";
import {useTranslation} from "react-i18next";
import API from "../../utils/API";
import {API_ENDPOINTS} from "../../apiConfig";


function ForgotPassword() {
    const [email, setEmail] = useState('');
    const {t} = useTranslation();
    const navigate = useNavigate();


    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const {data} = await API.post(API_ENDPOINTS.user.resetPassword, {email});
            if (data.status === 'ok') {
                alert('Link na obnovu hesla odeslán');
                navigate('/');
            }
        } catch (error) {
            console.error("Failed to fetch devices:", error);
        }

        console.log('Žádost o obnovu hesla pro:', email);
    };

    return (
        <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100">
            <div className="max-w-lg w-full space-y-8 p-10 bg-white rounded-lg shadow-md">
                <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
                    {t('passwordRecovery')}
                </h2>
                <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
                    <Forms.Email
                        name="email"
                        value={email}
                        onChange={val => setEmail(val)}
                        placeholder={t('typeYourEmail')}
                        className="rounded-md"
                    />
                    <div>
                        <button
                            type="submit"
                            className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                            {t('send')}
                        </button>
                    </div>
                    <div className="text-sm text-center">
                        <Link to="/" className="font-medium text-indigo-600 hover:text-indigo-500">
                            {t('backToLogin')}
                        </Link>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default ForgotPassword;
