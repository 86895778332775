import React, {useState, useEffect} from 'react';
import {NavLink, useLocation} from 'react-router-dom';
import routes from "../routesConfig";
import {useTranslation} from "react-i18next";

const menuItems = [
    {path: routes.admin.dashboardFull, label: 'dashboard'},
    {path: routes.admin.purchaseFull, label: 'purchase'},
    {path: routes.admin.repairsFull, label: 'repairManagement'},
    {
        path: routes.admin.part.base,
        label: 'warehouseManagement',
        submenu: [
            {path: routes.admin.part.categoriesFull, label: 'categories'},
            {path: routes.admin.part.itemsFull, label: 'warehouse.items'},
        ],
    },
    {
        path: routes.admin.device.base, label: 'deviceManagement',
        submenu: [
            {path: routes.admin.device.categoriesFull, label: 'categories'},
            {path: routes.admin.device.itemsFull, label: 'Zařízení'},
            {path: routes.admin.device.repairCatalogFull, label: 'Katalog oprav'},
        ],
    },
];

const LeftMenu = () => {
    const {t} = useTranslation();
    const location = useLocation();
    const [visibleSubMenus, setVisibleSubMenus] = useState({});

    useEffect(() => {
        const activeSubmenuPath = menuItems.find(item =>
            item.submenu && location.pathname.startsWith(item.path)
        )?.path;

        if (activeSubmenuPath) {
            setVisibleSubMenus({[activeSubmenuPath]: true});
        } else {
            setVisibleSubMenus({});
        }
    }, [location.pathname]);

    const toggleSubMenu = (path) => {
        setVisibleSubMenus(prevState => ({
            ...Object.keys(prevState).reduce((acc, key) => {
                acc[key] = false;
                return acc;
            }, {}),
            [path]: !prevState[path]
        }));
    };

    const getNavLinkClass = ({isActive}) =>
        isActive ? "block p-2 hover:bg-gray-700 rounded bg-gray-900" : "block p-2 hover:bg-gray-700 rounded";

    const isItemActive = (itemPath, exact = false) => {
        if (exact) {
            return location.pathname === itemPath;
        }
        return location.pathname.startsWith(itemPath);
    };

    return (
        <nav className="bg-gray-800 text-white w-64 space-y-2 p-2">
            <ul>
                {menuItems.map((item) => (
                    <li key={item.path} className="p-3">
                        <NavLink to={item.path} onClick={() => item.submenu && toggleSubMenu(item.path)}
                                 className={getNavLinkClass({isActive: isItemActive(item.path, !item.submenu)})}>
                            {t(item.label)}
                        </NavLink>
                        {item.submenu && visibleSubMenus[item.path] && (
                            <ul className="pl-4">
                                {item.submenu.map((subItem) => (
                                    <li key={subItem.path}>
                                        <NavLink to={subItem.path} className={getNavLinkClass}>
                                            {t(subItem.label)}
                                        </NavLink>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </li>
                ))}
            </ul>
        </nav>
    );
};

export default LeftMenu;
