import React, {useState, useEffect} from 'react';
import {API_ENDPOINTS} from "../../../apiConfig";
import API from "../../../utils/API";
import DataGrid from "../../../components/DataGrid";
import {Modal, Box, Typography, Button} from '@mui/material';
import Forms from "../../../components/Forms";

const Repairs = () => {
    const [categories, setCategories] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [newCategoryName, setNewCategoryName] = useState('');
    const [newCategoryDescription, setNewCategoryDescription] = useState('');

    const fetchRepairs = async () => {
        const response = await API.get(API_ENDPOINTS.repairs.list);
        setCategories(response.data);
    };

    useEffect(() => {
        fetchRepairs()
    }, []);

    const columns = [
        {field: 'id', headerName: 'ID', width: 90},
        {field: 'name', headerName: 'Název', width: 150},
        {field: 'description', headerName: 'Popis', width: 150},
    ];

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleAddCategory = async () => {
        try {
            const response = await API.post(API_ENDPOINTS.part.categories, {
                name: newCategoryName,
                description: newCategoryDescription,
            });
            setCategories([...categories, response.data]); // Přidání nově vytvořené kategorie do seznamu
            setIsModalOpen(false); // Zavření modálního okna
            // Resetování formuláře
            setNewCategoryName('');
            setNewCategoryDescription('');
        } catch (error) {
            console.error("Nepodařilo se přidat kategorii:", error);
        }
    };

    return (
        <div>
            <Button variant="contained" color="primary" onClick={() => setIsModalOpen(true)}>
                Zadat opravu
            </Button>
            <DataGrid rows={categories} columns={columns}/>
            <Modal
                open={isModalOpen}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                }}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Zadat novou opravu
                    </Typography>
                    <Forms.Text
                        required
                        placeholder="Název"
                        value={newCategoryName}
                        onChange={(val) => setNewCategoryName(val)}
                    />
                    <Forms.Text
                        required
                        placeholder="Popis"
                        value={newCategoryDescription}
                        onChange={(val) => setNewCategoryDescription(val)}
                    />
                    <Forms.Text
                        required
                        placeholder="poslední"
                        value={newCategoryDescription}
                        onChange={(val) => setNewCategoryDescription(val)}
                        className=""
                    />
                    <Button onClick={handleAddCategory} variant="contained" color="primary">
                        Přidat
                    </Button>
                </Box>
            </Modal>
        </div>
    );
};

export default Repairs;
