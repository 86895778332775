import React, {useState, useEffect, useCallback} from 'react';
import {API_ENDPOINTS} from "../../../apiConfig";
import DataGrid from "../../../components/DataGrid";
import ConfirmModal from "../../../components/ConfirmModal";
import API from "../../../utils/API";
import moment from 'moment';
import {useTranslation} from 'react-i18next';
import Forms from "../../../components/Forms";
import {ReactComponent as DeleteIcon} from '../../../assets/images/delete.svg';
import {ReactComponent as EditIcon} from '../../../assets/images/edit.svg';
import DeviceModal from "../../../components/DeviceModal";

const Devices = () => {
    const [deviceToEdit, setDeviceToEdit] = useState(null);
    const [devices, setDevices] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
    const [deviceToDelete, setDeviceToDelete] = useState(null);
    const {t} = useTranslation();
    const [error, setError] = useState();

    const fetchCategories = useCallback(async () => {
        try {
            const response = await API.get(API_ENDPOINTS.device.list);
            setDevices(response.data);
        } catch (error) {
            console.error("Failed to fetch devices:", error);
        }
    }, []);

    useEffect(() => {
        fetchCategories();
    }, []);

    const columns = [
        {
            key: 'name',
            name: t('name'),
            width: "20%",
            renderCell: ({row}) => (
                <label className="flex justify-between">
                    {row.name}
                    <div className="flex">
                        <DeleteIcon className="w-4 cursor-pointer fill-red-700 m-2"
                                    onClick={() => handleDeleteDevice(row)}/>
                        <EditIcon className="w-4 cursor-pointer text-blue-800 h-full"
                                  onClick={() => handleEditDevice(row)}/>
                    </div>
                </label>
            )
        },
        {
            width: "10%",
            key: 'category', name: t('category'), renderCell: ({row}) => row.category.map(cat => cat.name)
        },
        {
            width: "40%",
            key: 'repairList',
            name: t('repairList'),
            renderCell: ({row}) => row.repairList.map(repair => repair.repair.name).join(', ')
        },
        {
            width: "15%",
            key: 'createdBy',
            name: t('createdBy'),
            renderCell: ({row}) => `${row.createdBy.name} ${row.createdBy.surname}`
        },
        {
            width: "15%",
            key: 'createdAt',
            name: t('createdAt'),
            renderCell: ({row}) => moment(row.createdAt).format('DD.MM.YYYY HH:mm')
        }
    ];

    const handleDeleteDevice = (category) => {
        setDeviceToDelete(category);
        setIsConfirmModalOpen(true);
    };

    const handleEditDevice = (device) => {
        setDeviceToEdit(device);
        setIsModalOpen(true);
    };

    const confirmDeleteDevice = async () => {
        try {
            await API.delete(`${API_ENDPOINTS.device.base}/${deviceToDelete._id}`);
            setDevices(devices.filter(category => category._id !== deviceToDelete._id));
            setIsConfirmModalOpen(false);
        } catch (error) {
            setError(error);
            console.error("Failed to delete repair:", error);
        }
    };

    const onCloseConfirmModal = () => {
        setIsConfirmModalOpen(false);
        setError(null);
    }

    const openNewDeviceModal = () => {
        setIsModalOpen(true);
        setDeviceToEdit(null);
    }

    return (
        <div>
            <Forms.Button label={t('addDevice')} onClick={openNewDeviceModal} className="mb-8"/>
            <DataGrid showCreatedBy rows={devices} columns={columns}/>
            <DeviceModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                onDeviceUpdate={fetchCategories}
                device={deviceToEdit}
            />
            <ConfirmModal
                isOpen={isConfirmModalOpen}
                onClose={onCloseConfirmModal}
                error={error}
                onConfirm={confirmDeleteDevice}
                title="Confirm Deletion"
                message={t('confirmDeviceDeletion', {title: deviceToDelete?.name})}
            />
        </div>
    );
};

export default Devices;
