import React, { useState, useEffect } from 'react';
import API from '../../utils/API'; // Upravte import podle vaší struktury

const Dashboard = () => {
    const [data, setData] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Předpokládáme, že endpoint '/dashboard-data' vrací potřebná data
                const response = await API.get('/dashboard-data');
                setData(response.data);
            } catch (error) {
                console.error('Nastala chyba při načítání dat: ', error);
                // V případě chyby nastavte data na prázdný objekt nebo zachovejte data nezměněná
                setData({});
            }
        };

        fetchData();
    }, []);

    if (!data) {
        // Zobrazit nějaký jednoduchý indikátor načítání nebo nic
        return <div className="text-center p-4">Načítání...</div>;
    }

    return (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 p-4">
            <div className="p-4 bg-blue-500 text-white rounded-lg shadow-lg">
                Počet oprav ve frontě: {data.repairsCount}
            </div>
            <div className="p-4 bg-green-500 text-white rounded-lg shadow-lg">
                Počet zařízení skladem: {data.devicesCount}
            </div>
            <div className="p-4 bg-yellow-500 text-white rounded-lg shadow-lg">
                Počet náhradních dílů: {data.partsCount}
            </div>
            <div className="p-4 bg-red-500 text-white rounded-lg shadow-lg">
                Počet TODO: {data.todoCount}
            </div>
        </div>
    );
};

export default Dashboard;
