import React, {useState, useEffect, useRef} from 'react';
import {API_ENDPOINTS} from "../../../apiConfig";
import DataGrid from "../../../components/DataGrid";
import ConfirmModal from "../../../components/ConfirmModal";
import API from "../../../utils/API";
import moment from 'moment';
import {useTranslation} from 'react-i18next';
import Forms from "../../../components/Forms";
import {ReactComponent as DeleteIcon} from '../../../assets/images/delete.svg';
import {ReactComponent as EditIcon} from '../../../assets/images/edit.svg';
import PartModal from "../../../components/PartModal";

const Parts = () => {
    const [categories, setCategories] = useState([]);
    const [filteredCategories, setFilteredCategories] = useState([]);
    const [filterText, setFilterText] = useState(''); // Stav pro uložení filtračního textu
    const [selectedCategories, setSelectedCategories] = useState([]); // Stav pro zaškrtnuté kategorie
    const [availableCategories, setAvailableCategories] = useState([]); // K dispozici kategorie pro filtr
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
    const [partToDelete, setPartToDelete] = useState(null);
    const {t} = useTranslation();
    const [error, setError] = useState();
    const [partToEdit, setPartToEdit] = useState();

    const filterInputRef = useRef(null); // Ref pro textové pole filtru

    const fetchParts = async () => {
        const response = await API.get(API_ENDPOINTS.part.list);
        setCategories(response.data);
        setFilteredCategories(response.data);
        const uniqueCategories = [
            ...new Set(response.data.flatMap((part) => part.categoryList.map((cat) => cat.name)))
        ];
        setAvailableCategories(uniqueCategories);
    };

    useEffect(() => {
        fetchParts();
    }, []);

    useEffect(() => {
        const filtered = categories.filter((part) => {
            const filterLowerCase = filterText.toLowerCase();

            const matchesName = part.name.toLowerCase().includes(filterLowerCase);

            const matchesCategory = part.categoryList.some(cat =>
                cat.name.toLowerCase().includes(filterLowerCase) ||
                cat.code?.toLowerCase().includes(filterLowerCase)
            );

            const matchesCategorySelection = selectedCategories.length === 0 ||
                part.categoryList.some(cat => selectedCategories.includes(cat.name));

            return (matchesName || matchesCategory) && matchesCategorySelection;
        });
        setFilteredCategories(filtered);
    }, [filterText, selectedCategories, categories]);

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (!e.shiftKey && filterInputRef.current && document.activeElement !== filterInputRef.current && !isModalOpen && !isConfirmModalOpen) {
                filterInputRef.current.focus();
            }

            if (e.shiftKey && e.key === 'N') {
                e.preventDefault();
                openNewDeviceModal();
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [isModalOpen, isConfirmModalOpen]);

    const handleFilterKeyDown = (e) => {
        if (e.key === 'Escape') {
            setFilterText('');
        }
    };

    const handleDeletePart = (part) => {
        setPartToDelete(part);
        setIsConfirmModalOpen(true);
    };

    const handleEditPart = (device) => {
        setPartToEdit(device);
        setIsModalOpen(true);
    };

    const handleCategoryChange = (category) => {
        if (selectedCategories.includes(category)) {
            setSelectedCategories(selectedCategories.filter((cat) => cat !== category));
        } else {
            setSelectedCategories([...selectedCategories, category]);
        }
    };

    const columns = [
        {
            key: 'name',
            name: t('name'),
            renderCell: ({row}) => (
                <label className="flex justify-between">
                    {row.name}
                    <div className="flex">
                        <EditIcon className="w-4 cursor-pointer text-blue-800 h-full"
                                  onClick={() => handleEditPart(row)}/>
                        <DeleteIcon className="w-4 cursor-pointer fill-red-700 m-2"
                                    onClick={() => handleDeletePart(row)}/>
                    </div>
                </label>
            )
        },
        {key: 'description', name: t('description')},
        {key: 'storagePlace', name: t('storagePlace')},
        {
            key: 'categoryList',
            name: t('categoryList'),
            renderCell: ({row}) => row?.categoryList.map(cat => cat.name).join(', ')
        },
        {
            key: 'compatibleWith',
            name: t('compatibleWith'),
            renderCell: ({row}) => row?.compatibleWith.map(dev => dev.name).join(', ')
        },
        {
            key: 'createdBy',
            name: t('createdBy'),
            renderCell: ({row}) => `${row.createdBy.name} ${row.createdBy.surname}`
        },
        {
            key: 'createdAt',
            name: t('createdAt'),
            renderCell: ({row}) => moment(row.createdAt).format('DD.MM.YYYY HH:mm')
        }
    ];

    const confirmDeletePart = async () => {
        try {
            await API.delete(`${API_ENDPOINTS.part.categories}/${partToDelete._id}`);
            setCategories(categories.filter(category => category._id !== partToDelete._id));
            setIsConfirmModalOpen(false);
        } catch (error) {
            setError(error);
            console.error("Failed to delete category:", error);
        }
    };

    const openNewDeviceModal = () => {
        setIsModalOpen(true);
        setPartToEdit(null);
    };

    const onCloseConfirmModal = () => {
        setIsConfirmModalOpen(false);
        setError(null);
    };

    return (
        <div>
            <Forms.Button label={t('addPart')} onClick={openNewDeviceModal} className="mb-8"/>

            <div className="grid grid-cols-4 items-end gap-4 mb-4">
                <Forms.Text
                    ref={filterInputRef}
                    value={filterText}
                    onChange={val => setFilterText(val)}
                    onKeyDown={handleFilterKeyDown}
                    label={t('name')}
                    placeholder={t('searchByName')}
                />
                <div className="self-end">
                    <label className="mb-2 block">{t('filterByCategory')}</label>
                    <div className="category-filters flex flex-col gap-2">
                        {availableCategories.map((category) => (
                            <Forms.Checkbox
                                key={category}
                                label={category}
                                checked={selectedCategories.includes(category)}
                                onChange={() => handleCategoryChange(category)}
                            />
                        ))}
                    </div>
                </div>
            </div>
            <DataGrid showCreatedBy rows={filteredCategories} columns={columns}/> {/* Použijeme filtrová data */}
            <PartModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                onPartUpdate={fetchParts}
                part={partToEdit}
            />
            <ConfirmModal
                isOpen={isConfirmModalOpen}
                onClose={onCloseConfirmModal}
                error={error}
                onConfirm={confirmDeletePart}
                title={t('confirmDeletion')}
                message={t('confirmPartDeletion', {title: partToDelete?.name})}
            />
        </div>
    );
};

export default Parts;
